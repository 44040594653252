#containerCanvas {
    line-height: 0;
}

.parentContainer {
    display: flex;
    justify-content: center;
}

#chatContainer {  
    margin-left: 8px;
    width: 340px;
    min-width: 340px;
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
}

#playerList {
    background-color: white;
    min-width: 190px;
    margin: 0px 6px 0px 0px;
    border:1px solid #eee;
    border-radius: 3px;
}

#can {
    border: 1px solid;
    background-color: white;
}

.gameScreen {
    margin-bottom: 64px;
    display: flex;
    flex-direction: column;
}

.overlayContainer {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 2;
    cursor: pointer;
}
.overlayContent {
 position: absolute;
  top: 45%;
  left: 50%;
  font-size: 50px;
  color: white;
  transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
}
