
.chats {
    height: 600px;
    overflow-y: scroll;
    background-color:aqua;
}

.chats p {
    padding: 7px;
    margin: 0;
    word-wrap: break-word;
}

#chatInput { 
    height: 34px;  
}