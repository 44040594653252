.playerDataContainer {
    border:1px solid #eee;
    border-radius: 3px;
}

.playerDataContainer:nth-child(odd) {
    background-color: #EEE;
}

.playerData {
    border-bottom: 1px solid lightblue;
}