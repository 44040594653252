#menuContainer {
    padding:20px;
    position: relative;
    top:2em;
    width:22em;
    margin:auto auto;
    background-color: rgba(59, 109, 246, 0.93);
    border-radius: 5px;
    color:white;
    font-size: larger;
}

.howToPlay {
    margin-top: 30px;
    text-align: left;
}

.warn {
    color:Red;
    font-weight: 600;
}

.appContainer {
    margin: 0 auto;
    margin-top: 10vh;
}