.App {
  text-align: center;
}

* {
  font-family: 'Pangolin', cursive;
}

body {
  background: url('./assets/background.png') center fixed;
  background-size: 256px;
}

input {
  outline: none;
  border-radius: 5px;
}